@import "/src/style/variables.scss";
@import "/src/style/mixins";

.container {
  @include modal_container;
}

.modal_window {
  @include modal_window;
}

.modal_header {
  @include modal_header;
  height: 30px;

  .close_btn_wrapper {
    position: absolute;
    right: 20px;

    button {
      @include blue-btn;
    }
  }
}

.modal_body {
  @include modal_body;

  span {
    font-weight: bolder;
    margin-left: 5px;
    margin-right: 5px;
  }

  text-align: center;

  input[type="text"] {
    margin-left: 0;
    border: 1px solid rgb(160, 157, 157);
    border-radius: 5px;
    width: 120px;
    text-align: center;
  }
}

.warning_message {
  color: $color-warning-message;
  margin-bottom: 20px;
  font-weight: bold;

  p {
    font-size: 0.8rem;
    padding: 0;
    margin: 0;
    text-align: center;
  }
}

.modal_footer {
  .buttons_wrapper {
    display: flex;
    justify-content: center;
    gap: 5px;

    button {
      @include blue-btn;
    }
  }

  .input_wrapper {
    display: grid;
    grid-template-columns: 4fr 8fr;
    justify-content: start;
    margin-bottom: 20px;

    label {
      margin-right: 5px;
      font-size: 0.9rem;
    }

    input {
      border-radius: 5px;
      border: 1px solid gray;
      outline: none;
    }
  }

  .error_message {
    color: red;
    font-style: italic;
  }
}
