@import "/src/style/variables.scss";
@import "/src/style/mixins.scss";

.wrapper {
  display: grid;
  grid-template-columns: 5fr 5fr 2fr;
  padding: 20px;
  gap: 10px;
  ;

  @media screen and (max-width: $breakpoint-lg) {
    grid-template-columns: 8fr 4fr;

  }

  .static_properties,
  .change_log,
  .flow_control {

    display: grid;
    grid-template-rows: 30px auto;
    background-color: $bg-node-nav-btn;
    box-shadow: $shadow-node-nav-container;
    padding: 20px;

    input,
    textarea,
    select {
      &:disabled {
        @include disabled_input;
      }
    }

    textarea {
      resize: none;
    }

    .grid_3_cols {
      display: grid;
      grid-template-columns: repeat(3, 4fr);
      gap: 10px;

      input {
        width: 100px;
      }
    }
  }

  .static_properties {
    display: grid;
    grid-template-rows: 30px auto;
    border-radius: 5px;

    input {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    form {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;

      label {
        font-size: 0.8rem;
      }

      section {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    @media screen and (max-width: $breakpoint-lg) {
      grid-row: 1;
      grid-column: 1/3;
    }
  }

  .change_log {

    @media screen and (max-width: $breakpoint-lg) {
      grid-row: 2;
      grid-column: 1;
    }
  }

  .flow_control {

    .start_stop_wrapper{
      display: flex;
      justify-content: center;
      justify-self: center;
      width: 200px;
      margin-bottom: 5px;
      gap: 5px;
      button{
        @include blue_btn;

        width: 100%;
      }
    }
    select {
      display: flex;
      justify-self: center;
      align-self: center;
      width: 200px;
      height: 50px;
      background-color: $bg-top-menu-btn-default;
      color: white;
      border-radius: 5px;
      border: 0;
      font-weight: bold;
      font-size: 1.2rem;
      text-align: center;
      outline: none;
    }

    @media screen and (max-width: $breakpoint-lg) {
      grid-row: 2;
      grid-column: 2;
    }
  }

  .blocks_table {

    grid-column: 1/4;
    max-height: 700px;
    overflow-y: auto;

    table {
      @include server_table;
      table-layout: fixed;


      .centered_table_data {
        text-align: center;
      }

      td {
        font-size: 0.9rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

    }

    @media screen and (max-width: $breakpoint-lg) {
      grid-row: 4;
    }
  }

  header {
    font-weight: $font-weight-bold;
    text-align: center;

  }

  input {
    border-radius: 3px;
  }
}