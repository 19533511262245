@import "/src/style/mixins.scss";

.section_container {
  @include section_container;
}

.section_header {
  @include section_header_container;
  position: relative;

  span {
    position: absolute;
    right: 20px;
    top: 15px;
    cursor: pointer;
  }
}

.text_area_container {
  display: flex;
  align-items: center;
  justify-content: center;

  &.collapsed {
    height:0;
    textarea{
      display: none;
    }
   
  }

  &.expanded {
   height: 200px;
  }

  transition: height 0.3s ease-in-out;

  textarea {
    height: 100%;
    width: 100%;
    resize: none;
    outline: none;
    border: 1px solid rgb(228, 228, 228);
    background-color: rgb(248, 248, 248);
    box-shadow: $shadow-inset;
    padding: 5px;
  }
}