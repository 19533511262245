@import "/src/style/variables.scss";
@import "/src/style/mixins.scss";

.wrapper {
    position: relative;

    .add_property_container {
        display: flex;
        justify-content: center;

    }

    form {
        display: flex;
        flex-direction: column;


        .input_wrapper,
        .value_wrapper {

            @include section_grid_item;

            border-bottom: 1px solid $color-list-border;

            label {
                padding-left: 10px;
                margin-right: 20px;
                font-size: 0.9rem;
            }

            input {
                outline: none;
                border: 0;

            }


        }

        .param_value {
            position: relative;

        }

        .btn_wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1px;


        }


    }

    button {
        cursor: pointer;
        width: 100%;
        border: 0;
        height: 40px;
        font-size: 0.7rem;
        text-align: center;
        background-color: $color-node-nav-icon;
        color: white;
        padding: 5px;
    }

    .error_message {
        color: red;
    }
}