@import "/src/style/variables.scss";
@import "/src/style/mixins.scss";

.container {
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
}


.modal_window {
    padding: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    color: rgb(0, 0, 0);
    z-index: 9999;
    border-radius: 16px;
    box-shadow: $shadow-node-nav-container;
    overflow: wrap;
    min-width: 400px;
    min-height: 100px;
    color: $color-font-main;

}


.modal_header {
    text-align: center;
    font-weight: $font-weight-bolder;
    margin-bottom: 20px;

}


.modal_body {
    margin-bottom: 20px;
    font-size: 0.9rem;

    .form {
        display: grid;

        gap: 20px;
        align-items: start;

        label,
        input {
            display: inline-block;
            margin-bottom: 10px;
        }

        label {
            font-weight: bold;
        }

        .text_values_wrapper {

            textarea {
                resize: none;
                border: 1px solid $color-list-border-darker;
                min-height: 100px;
                padding: 5px;
            }

            .text_values_item {
                display: grid;
                grid-template-columns: 4fr 8fr;
            }

            label,
            input {
                display: inline-block;

            }

            input {

                border-radius: 5px;
                border: 1px solid $color-list-border-darker;
                padding-left: 5px;

            }
        }

        .dropdown_wrapper {


            .dropdown_item {
                display: grid;


                label {
                    font-weight: 400;
                }

                select {
                    overflow-y: auto;
                    padding: 5px;

                    border: 1px solid $color-list-border-darker;
                }

            }
        }

        .checkboxes_wrapper {
            display: flex;
            flex-direction: column;


            .checkboxes_item {
                position: relative;
                display: grid;
                grid-template-columns: 8fr 4fr;
                justify-items: left;

                input {
                    position: absolute;
                    right: 0;
                    margin-right: 0;
                    width: 20px;
                }

            }
        }

        .form_btns_wrapper {
            display: flex;
            justify-self: end;


            button {
                @include blue_btn;

                margin-left: 5px;
            }
        }

    }

}