@import "/src/style/variables.scss";
@import "/src/style/mixins.scss";

.wrapper {
  background-color: white;

  .header {
    display: grid;
    grid-template-columns: 4fr 4fr 4fr;

    .log_search_btn_wrapper{
      display: flex;
      align-items: center;
      margin-left: 20px;

      button{
        @include blue-btn;
        margin-right: 5px;
      }
    }
  }

  .server_status {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
  }

  .header_buttons {
    grid-column: 2;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 10px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      width: 60px;
      font-size: 1.8rem;
      background-color: rgb(243, 243, 243);
      border: 1px solid rgb(224, 224, 224);
      border-radius: 5px;
      margin-right: 10px;

      &:hover {
        background-color: rgb(70, 70, 70);
      }

      &.play {
        color: $bg-top-menu-btn-default;
      }

      &.stop {
        color: red;
      }

      &.kill {
        color: orange;
      }

      transition: background-color 0.3s ease-in-out;
    }
  }

  .main_table {
    @include server_table;
  }
}