.wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;

    header{
        margin-bottom: 20px;
    }
    
    select{
        margin-top: auto;
        margin-bottom: auto;
        width: 100%;
     
    }
}