@import "/src/style/variables.scss";
@import "/src/style/mixins.scss";

.container {
    @include modal_container;
}


.modal_window {
    @include modal_window;

}


.modal_header {
    @include modal_header;

}


.modal_body {
    @include modal_body;

    .form {
        @include modal_form;

        label,
        input {
            display: inline-block;
            margin-bottom: 10px;


        }

        label {
            font-weight: bold;
        }

        .text_values_wrapper {

            .text_values_item {
                display: grid;
                grid-template-columns: 4fr 8fr;

            }

            label,
            input {
                display: inline-block;

            }

            input {
                width: 100%;
                border-radius: 5px;
                border: 1px solid $color-list-border-darker;
                padding-left: 5px;

            }


            .generate_password_btn {
                position: absolute;
                top: 117px;
                right: 12px;

                button {
                    @include blue_btn;
                    font-size: 0.6rem;
                    padding: 3px;
                }
            }
        }

        .dropdown_wrapper {

            .dropdown_item {
                display: grid;

                select {
                    overflow-y: auto;
                    padding: 5px;
                }

                .group_wrapper {
                    max-height: 100px;
                    border: 1px solid $color-list-border-darker;
                    padding: 5px;
                    border-radius: 5px;
                    overflow-y: auto;
                    margin-bottom: 10px;

                    .group_wrapper_item {
                        display: grid;
                        grid-template-columns: 8fr 4fr;
                        align-items: start;
                    }

                    label {
                        font-weight: 400;
                    }
                }
            }
        }

        .checkboxes_wrapper {
            display: flex;
            flex-direction: column;


            .checkboxes_item {
                position: relative;
                display: grid;
                grid-template-columns: 8fr 4fr;
                justify-items: left;

                input {
                    position: absolute;
                    right: 0;
                    margin-right: 0;
                    width: 20px;
                }

            }
        }

        .form_btns_wrapper {
            display: flex;
            justify-self: end;


            button {
                @include blue_btn;

                margin-left: 5px;
            }
        }

    }

}