@import "/src/style/variables.scss";
@import "/src/style/mixins.scss";

.my-anchor-element {
  background-color: red;
  overflow: visible;
}

.node_list_btn {
  @include left_panel_btn($bg-node-nav-btn);
  padding-left: 15px;
  width: 100%;
}

.node_list_icon {
  @include node_list_icon;
}
