//NAV
@mixin nav_wrapper {
  background-color: $bg-top-menu-btn-default;
  display: grid;
  grid-template-columns: repeat(3, 4fr);
  align-items: center;
}

@mixin section_container {
  h5 {
    color: $color-node-nav-container-header;
    background-color: $bg-node-nav-btn;
    margin: 0;
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 10px;
  }

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    display: grid;
    grid-template-columns: 120px auto;
    border-bottom: 1px solid rgb(238, 238, 238);
    list-style: none;
    height: 40px;

    input {
      border: 0;
      outline: none;
      max-width: auto;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    input,
    label {
      padding-left: 10px;
      font-size: 0.8rem;
    }

    label {
      color: $color-node-nav-container-header;
      max-width: 90px;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        background-color: $bg-node-nav-btn;
      }

      transition: background-color 0.3s ease-in-out;
    }

    input[type="text"] {
      width: 100%;
    }

    input[type="color"] {
      border: 0;
      border-radius: 50%;
      margin-top: auto;
      margin-bottom: auto;
    }

    input[type="radio"] {
      width: 100%;
    }

    input[type="checkbox"] {
      margin-top: auto;
      margin-bottom: auto;
      height: 20px;
      margin-left: 10px;
    }
  }
}

@mixin nav_right_section {
  display: flex;
  justify-self: end;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 1000;
}

//DROPDOWN
@mixin dropdown_menu_wrapper {
  position: absolute;
  top: 35px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  padding: auto;
  border-radius: 3px;

  ul {
    padding: 0;
  }

  li {
    display: grid;
    grid-template-columns: 6fr 6fr;
    padding: 10px;
    text-align: left;
    color: black;
    font-size: 0.9rem;

    &:hover {
      background-color: lighten($color-node-nav-icon, 55%);
    }

    transition: background-color 0.3s ease;
  }
}

@mixin dropdown_menu_section_title {
  text-align: center;
  color: black;
  padding: 5px;
  border-bottom: 1px solid rgb(230, 230, 230);
  background-color: rgb(245, 245, 245);
}

//Right Panel
@mixin section_header_container {
  padding-top: 15px;
  color: $color-node-nav-container-header;
  margin-bottom: 15px;
  text-align: center;
}

//Right Panel Props
@mixin section_grid_item {
  border-right: 1px solid rgb(238, 238, 238);
  height: 40px;
  display: flex;
  align-items: center;

  select {
    border: none;
    width: 100%;
  }
}

@mixin notification_icon_wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 40px;
}
