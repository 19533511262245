@import "/src/style/variables.scss";
@import "/src/style/mixins.scss";

.wrapper {
  header {
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }

  .table_wrapper {
    max-height: 200px;
    overflow-y: auto;

    table {
      @include loaded_flows_table;

      thead {
        position: sticky;
        top: 0;
        background-color: #f1f1f1;
        z-index: 1;
      }
    }
  }

  li {
    list-style: none;
    color: $color-node-nav-icon;
    font-weight: bold;
    font-size: 1 rem;
    cursor: pointer;
  }

  ul {
    padding: 0;
    max-height: 200px;
    overflow-y: auto;
  }

  .footer_btn {
    background-color: $bg-top-menu-btn-default;
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
  }

  .action_delete_btn,
  .action_confirm_btn {
    border: 0;
    padding: 5px;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 3px;
    font-size: 0.7rem;
  }

  .action_delete_btn {
    @include red_btn;
    font-size: 0.6rem;
  }

  .action_confirm_btn {
    @include blue_btn;
    font-size: 0.6rem;
  }
}
