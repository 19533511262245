@import "/src/style/mixins.scss";
@import "/src/style/variables.scss";

.section {
  position: relative;
  overflow-x: auto;
  background-color: $bg-node-nav-container;
  box-shadow: rgba(226, 226, 226, 0.3) 0px 7px 13px 7px inset;

  &:hover {
    background-color: darken($color: $bg-node-nav-container, $amount: 5f);
  }

  &.opened {
    width: 100%;
    transition: width 0.3s ease-out;
  }

  &.closed {
    width: 0px;
    transition: width 0.3s ease-out;
  }

  transition: width 0.3s ease-out;
  transition: background-color 0.3s ease-in-out;

  ul {
    margin: 0;
    padding: 0;
  }

  //test

  li {
    @include server_flow_list;

    padding-left: 0;
    cursor: pointer;
  }

  h5 {
    cursor: pointer;
  }

  .section_header {
    display: flex;
    align-items: center;

    .section_title {
      color: $color-node-nav-container-header;
      text-align: left;
      padding-left: 20px;
    }

    .header_icon {
      padding-left: 10px;
      color: $color-font-main;
      font-size: 1.3rem;
    }

    .arrow_icon {
      position: absolute;
      top: 20px;
      right: 10px;
      cursor: pointer;
      color: $color-node-nav-container-header;
    }
  }
}

.flow_list_item {
  @include server_flow_list_item;
  height: 25px;
  position: relative;

  .flow_name {
    padding-left: 20px;

    @media screen and (max-width: $breakpoint-md) {
      max-width: 80px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  a {
    text-decoration: none;
    color: black;
  }

  &.selected {
    background-color: rgb(194, 194, 194);
    width: 100%;
  }
}

.flow_list_btn_wrapper {
  @include server_flow_list_btn_wrapper;
  button {
    @include blue-btn;
  }
}

.flow_list_dropdown_actions {
  display: flex;
  position: absolute;
  right: 5px;
  gap: 2px;

  .delete_btn,
  button {
    @include blue-btn;
    font-size: 0.7rem;
    margin-bottom: 2px;
  }

  .delete_btn {
    @include red_btn;
  }
}

.draft_list_item_wrapper {
  border-bottom: 1px solid rgb(204, 204, 204);
}

.folder_name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  height: 40px;
  color: $color-node-nav-container-header;
  cursor: pointer;
  span {
    margin-right: 10px;
  }

  .flow_list_title_wrapper {
    height: 100%;
    padding-left: 10px;
    display: flex;
    align-items: center;
  }
}
