@mixin blue_btn {
  background-color: $bg-top-menu-btn-default;
  padding: 5px 10px;
  border: 0;
  border-radius: 6px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  cursor: pointer;
  box-shadow: rgba(59, 59, 59, 0.2) 0px -2px 0px inset;
  text-transform: uppercase;
  &:hover {
    background-color: $bg-top-menu-btn-hover;
    box-shadow: none;
  }

  transition: background-color 0.2s ease-in-out;
}

@mixin red_btn {
  @include blue_btn();
  background-color: $bg-delete-btn;

  &:hover {
    background-color: $bg-delete-btn-hover;
  }
}

@mixin yellow_btn {
  @include blue_btn();
  background-color: $bg-warning-btn;

  &:hover {
    background-color: $bg-warning-btn-hover;
  }
}

@mixin grey_btn {
  @include blue_btn();
  background-color: $bg-cancel-btn;

  &:hover {
    background-color: $bg-cancel-btn-hover;
  }
}

@mixin white_btn {
  @include blue_btn();
  background-color: white;
  color: $color-font-main;

  &:hover {
    background-color: rgb(207, 207, 207);
  }
}

@mixin server_header_btn {
  background-color: white;
  border: 0;
  border-radius: 5px;
  padding: 5px 10px;
  font-weight: $font-weight-bold;
  color: $color-font-main;
  cursor: pointer;

  &:hover {
    background-color: rgb(148, 200, 230);
  }

  transition: background-color 0.2s ease-in-out;
}

//Left Panel
@mixin left_panel_btn($bg) {
  width: 100%;
  height: 40px;
  border: 0;
  background-color: $bg;
  color: $color-node-nav-btn;

  font-size: 0.9rem;
  border-bottom: 1px solid rgb(219, 219, 219);
  text-align: left;
  padding-left: 60px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: darken($color: $bg, $amount: 10%);
  }

  transition: background-color 0.3s ease;
}
