@import "/src/style/variables.scss";
@import "/src/style/mixins.scss";

.container {
    @include modal_container;
}

.modal_window {
    @include modal_window;

}

.modal_header {
    @include modal_header;

}

.modal_body {
    @include modal_body;

    .form {
        display: grid;

        gap: 20px;
        align-items: start;

        label,
        input {
            display: inline-block;
            margin-bottom: 10px;
        }

        label {
            font-weight: bold;
        }

        .lists_wrapper {
            display: grid;
            grid-template-columns: 6fr 6fr;
            gap: 20px;


            ul {
                margin-left: 0;
                padding-left: 5px;
            }

            li {
                list-style: none;
                margin-left: 0;
                padding-left: 0;

                width: 100%;

                .list_item_wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-left: 5px;
                    height: 25px;
                    margin-bottom: 10px;
                    cursor: pointer;
                }

                :hover {
                    background-color: rgb(240, 240, 240)
                }

                .delete_from_group_btn {
                    @include red_btn;
                    margin-left: 5px;
                }

                .add_to_group_btn {
                    @include blue_btn;
                    margin-left: 5px;
                }
            }


        }

        .form_btns_wrapper {
            display: flex;
            justify-self: end;


            button {
                @include blue_btn;

                margin-left: 5px;
            }
        }

    }

}