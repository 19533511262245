.wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  margin-bottom: 10px;
  max-height: 120px;

  input {
    outline: none;
    border: 1px solid rgb(207, 207, 207);
    border-radius: 3px;
  }

  .results {
    z-index: 99999;
    position: fixed;
    left: 310px;
    max-height: 300px;
    overflow-y: scroll;
    border: 1px solid rgb(201, 201, 201);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
}
