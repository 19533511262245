@import "/src/style/mixins.scss";
@import "/src/style/variables.scss";

.wrapper {
  @include dropdown_menu_wrapper;
  right: 5px;

  .settings_title {
    @include dropdown_menu_section_title
  }

  .settings_icon {
    @include dropdown_menu_icon;
  }

  ul {
    margin: 0 auto;
  }

  .check_input {
    display: flex;
    justify-content: flex-end;
  }

  .settings_dropdown {
    @include dropdown_menu_section_title;
  }

  .slider_value {
    position: absolute;
    left: 100px;
    font-size: small;
    top: 11px;
    left: 80px;
  }

  .slider_container {
    position: relative;
  }

  .logout_container {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;

    button {
      @include left_panel_btn($color-node-nav-icon);
      box-shadow: $shadow-node-nav-btn;
      text-align: center;
      font-size: 1rem;
      font-weight: 600;
      padding-left: 0;
      border-bottom: 0;
      width: 100px;
      border-radius: 5px;
      background-color: $color-node-nav-icon;
      color: rgb(253, 253, 253);

    }
  }



}