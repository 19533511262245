@import "/src/style/variables.scss";
@import "/src/style/mixins.scss";

.wrapper {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-right: 20px;
  height: 100%;
  position: relative;

  .icon {
    &.light {
      color: white;
    }

    &.dark {
      color: $color-node-nav-icon;
    }
    cursor: pointer;
    font-size: 2em;
  }

  .profile_wrapper {
    z-index: 1000;
    position: absolute;
    background-color: white;
    top: 50px;
    border: 1px solid $color-list-border-darker;
    padding: 10px;
    width: 300px;

    header {
      text-align: center;
      font-weight: $font-weight-bolder;
      margin-bottom: 20px;
    }

    main {
      display: flex;
      flex-direction: column;
    }

    .account_info_wrapper {
      position: relative;

      .edit_btn {
        position: absolute;
        top: -15px;
        right: 0;
        color: $color-list-label;
        cursor: pointer;
      }

      .account_info_item {
        display: flex;
        margin-bottom: 10px;

        label,
        p {
          display: inline-block;
          margin-top: 0;
          margin-bottom: 0;
        }

        p {
          margin-left: 10px;
        }
      }

      button {
        @include blue_btn;
        background-color: white;
        color: $color-list-label;

        &:hover {
          background-color: white;
          color: black;
        }
      }
    }
  }

  .btn_wrapper {
    display: flex;
    gap: 10px;

    button {
      @include blue_btn;
      margin-top: 10px;
      width: 100%;
    }

    .close_btn {
      @include grey_btn;
    }
  }
}
