@import "/src/style/variables.scss";
@import "/src/style/mixins.scss";

.wrapper {
  @include nav_wrapper;
  box-shadow: $shadow-server-top-nav;
  z-index: 9999;
  header {
    font-size: 1rem;
    color: white;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: $breakpoint-lg) {
      font-size: 1rem;
    }
  }

  .nav_btns_wrapper {
    display: flex;
    height: 100%;
    align-items: center;
    z-index: 1000;

    .designer_button,
    .server_button {
      button {
        @include white_btn;
        margin-left: 20px;
      }
    }
  }

  .settings_container {
    @include nav_right_section;
  }
}
