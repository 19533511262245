@import "/src/style/variables.scss";
@import "/src/style/mixins.scss";

.wrapper {
    position: relative;
}

.title {
    position: absolute;
    top: -60px;
    left: 0;


    font-size: 1.2rem;

}

.icon_set {
    position: absolute;
    top: -43px;
    right: 0;

    ul {
        display: flex;
        list-style: none;

        li {
            margin-left: 5px;
            font-size: 1rem;
            color: $color-node-nav-icon;
            z-index: 99999;
            cursor: pointer;

        }
    }

    .icon_delete {
        color: rgb(240, 78, 78);
        font-size: 1.2rem;
        margin-bottom: 20px;
    }
}

.text_input_wrapper {
    position: absolute;
    background-image: radial-gradient(circle, #f7f7f7, #ebe8e8);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    padding: 15px;
    top: -150px;
    left: 0;
    right: 0;
    z-index: 9999;
    max-width: 300px;
    display: flex;
    flex-direction: column;

    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;

    label {
        font-size: 1.5rem;
        font-weight: bold;

    }

    input[type="text"] {
        height: 20px;
        border: 1px solid $color-node-nav-icon;
        outline: none;
    }

    input[type="color"] {
        width: 100%;
        border: 0;
    }

    button {
        @include left_panel_btn($color-node-nav-icon);
        height: 25px;
        text-align: center;
        padding: 0;
        color: white;

    }

}