@import "/src/style/mixins.scss";
@import "/src/style/variables.scss";

.wrapper {
  .icon_wrapper {
    @include notification_icon_wrapper;
    .icon {
      @include notification_icon;
    }
    .badge {
      @include red_badge_icon;
    }
  }

  .notifications_list {
    @include notifications_list;

    .message {
        font-style: italic;
      }

      .date {
        font-size: 0.7rem;
      }

    .clear_btn {
      @include blue_btn;
    }
  }
}
