@import "/src/style/variables.scss";
@import "/src/style/mixins.scss";
;

.wrapper {

    display: flex;
    justify-content: flex-end;

    .settings_icon {
        padding-right: 20px;
        font-size: 1.5rem;
        color: $color-node-nav-icon;
        cursor: pointer;
    }

}