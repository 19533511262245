@import "/src/style/variables.scss";
@import "/src/style/mixins.scss";

.wrapper {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 5px;
    box-shadow: $shadow-top-menu-btn;

    header {
        margin-bottom: 10px;
        text-align: center;
    }

    .line {
       
        margin-bottom: 10px;
        background-color: white;
        border-radius: 5px;
        margin-left: auto;
        margin-right: auto;

        @media screen and (max-width:1700px) {
            margin-left: auto;
            margin-right: auto;
        }
    }

    .pie {
        border-radius: 5px;
        margin-bottom: 10px;
      
        margin-left: auto;
        margin-right: auto;

        @media screen and (max-width:1700px) {
            margin-left: auto;
            margin-right: auto;
        }
    }

    button {
        @include blue-btn;
        width: 100px;
        margin-left: auto;
        margin-right: auto;

    }
}