@import "/src/style/variables.scss";
@import "/src/style/mixins.scss";
;
@import "/src/style/variables.scss";
@import "/src/style/mixins.scss";
;

.node_wrapper {
    position: relative;

    &.outlined {
        border-radius: 5px;
        padding: 2.3px;

        background-color: rgb(47, 158, 202);
        transition: padding 0.1s ease-in-out;
    }

    .delete_btn_wrapper {
        position: absolute;
        top: -25px;
        right: 0;

        button {
            z-index: 9999;
            border-radius: 20px;
            border: 0;
            background-color: red;
            color: white;
            padding: 1px 6px;
            cursor: pointer;
        }
    }
}

.node_body {
    padding: 10px;
    min-width: 150px;
    min-height: 50px;
    border-radius: 5px;
    line-break: auto;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    font-weight: $font-weight-bolder;
    font-size: 1.1rem;
    letter-spacing: 1px;
    text-align: center;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
        opacity: 0.25;
        background-image: radial-gradient(circle, #ffffff, #7a7a7a);
        border-radius: 5px;

    }

    &.dark-text {
        color: white;
        transition: color 1s ease;

    }

    &.light-text {
        color: black;
        transition: color 1s ease;
    }
}

.node_icon_container {
    position: relative;
    padding-bottom: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.node_icon {
    position: absolute;
    left: 7px;
    top: 4px;
    font-size: 0.9rem;


    &.dark-text {
        color: rgb(255, 255, 255);
        transition: color 1s ease;
    }

    &.light-text {
        color: rgb(44, 149, 209);
        transition: color 1s ease;
    }
}

.node_title {
    position: absolute;
    top: 5px;
    right: 10px;
    font-style: italic;

    font-size: 0.7rem;
}

.node_label {
    font-size: 0.9rem;
    padding: 10px 20px;
}

.directions_wrapper{
    position: absolute;
    bottom:-20px;
    left: 0;
    display: flex;
    width: 100%;
    justify-content: center;
   
    select{
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;

        text-align: center;
    }
}

.handle {
    border: 0 !important;
    border-radius: 0 !important;

    background-color: $color-node-nav-icon !important;

    width: 12px !important;
    height: 45px !important;
    z-index: 9999 !important;
    opacity: 0.5 !important;

    &.right {
        right: -12px !important;
        border-top-right-radius: 5px !important;
        border-bottom-right-radius: 5px !important;
    }

    &.left {
        left: -12px !important;
        border-top-left-radius: 5px !important;
        border-bottom-left-radius: 5px !important;
    }


}