.tail_spin_container {
    background-color: rgba(153, 176, 194, 0.2);
    width: 200vw;
    height: 200vh;
    z-index: 9999;
    top: 50%;
    left: 50%;
    overflow-y: visible;
    transform: translate(-50%, -50%);
    position: fixed;
  }

  .tail_spin {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  
  }

  .error_message_container {
    width: 1000px;
    z-index: 9999;
    top: 50%;
    left: 50%;
    overflow-y: visible;
    transform: translate(-50%, -50%);
    position: fixed;
  }

  .error_message{
    position: fixed;
    font-size: 1.5rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    color: red;
  }
  