@import '/src/style/mixins.scss';
@import '/src/style/variables.scss';

.wrapper {

  @include section_container;

  ul{
    overflow-y: auto;
    overflow-x: hidden;
    @media screen and (max-height: $height-lg) {
      max-height: 300px;
    }

    @media screen and (max-height: $height-md) {
      max-height: 200px;
    }
  }
  .list_item {
    position: relative;
    
  }
}