@import "/src/style/variables.scss";
@import "/src/style/mixins.scss";


.wrapper {
    @include section_container;

    .custom_props_list {
        position: relative;
    }


}