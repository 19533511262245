@import "style/mixins.scss";
@import "style/variables.scss";

.wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgb(255, 255, 255);
  display: grid;
  grid-template-rows: 3fr 8fr 1fr;
  justify-content: center;

  .logo_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cocoon_logo_wrapper {
    display: flex;
    justify-self: center;
    width: 300px;
  }

  .form_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    form {
      box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
        rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
        rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
      background-image: linear-gradient(white, rgb(179, 209, 170));
      padding: 20px 40px 100px 40px;
      position: relative;

      font-weight: $font-weight-regular;
      font-variation-settings: "slnt";
      border-radius: 5px;

      height: 150px;

      input {
        padding: 10px;
        font-size: 1rem;
        border-radius: 5px;
        border: 1px solid rgb(174, 196, 209);
      }

      label,
      input {
        display: block;
      }

      label {
        text-align: center;
        margin-bottom: 5px;
        margin-top: 5px;
        font-size: 1.3rem;
        font-weight: bolder;
        color: #363636;
      }

      .error_message {
        color: red;
      }

      .btn_wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        display: flex;
        justify-content: center;

        button {
          width: 100%;
          height: 40px;
          border: 0;
          border-top: 1px solid #b9d3a1;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          font-size: 1.5rem;
          background-color: #aad4a6;
          font-weight: bolder;
          transition: background-color 0.3s ease-in-out;

          &:hover {
            background-color: #9bc298;
          }

          &:active {
            background-color: #84a581;
          }
        }
      }
    }
  }
}
