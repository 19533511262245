@import "/src/style/variables.scss";
@import "/src/style/mixins.scss";

.wrapper{
    padding: 20px;
    .header{
        margin-bottom: 10px;

        button{
            @include blue_btn;
            margin-right: 5px;
        }
    }

    .log_grid{
        display: grid;
        grid-template-columns: 3fr 3fr 6fr;
        gap: 10px;
      

        section{
            background-color: $bg-node-nav-btn;
            box-shadow: $shadow-node-nav-container;
            padding: 20px;
            header{
                
                text-align: center;
            }
        }
    }

}