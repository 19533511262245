@import "/src/style/variables.scss";
@import "/src/style/mixins.scss";

.container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  width: 500px;
  padding-top: 0;
  z-index: 1000;

  color: $color-node-nav-container-header;

  h3 {
    text-align: center;
  }

  .body {
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
  }

  .footer {
    display: flex;
    justify-content: center;
    button {
      @include blue_btn;
    }
  }
}
