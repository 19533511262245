@font-face {
    font-family: 'BlockMenuHeader';
    src: url('../assets/fonts/Rajdhani-Regular.ttf');
}

@font-face {
    font-family: 'Richard';
    src: url('../assets/fonts/Inter-Regular.ttf');
}

@font-face {
    font-family: 'Inter';
    src: url('../assets/fonts/Inter-VariableFont_slnt\,wght.ttf');
}