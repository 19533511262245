@import "/src/style/variables.scss";
@import "/src/style/mixins.scss";

.wrapper {

    .header_btn_wrapper {
        button {
            @include blue-btn;
        }

        margin-bottom: 10px;
    }
}