.suggestion_list {
    position: absolute;
    top: 40px;
    right: 0;
    z-index: 1000;

    ul {
        min-width: 150px;
        border: 1px solid rgb(168, 168, 168);
        list-style: none;
    }

    li {
        background-color: white;
        padding: 5px;
        font-weight: bold;
        height: 20px;
        border: 0;
        cursor: pointer;
    }

}