@import "/src/style/variables.scss";
@import "/src/style/mixins.scss";


  .delete_edge_button_wrapper {
    button {
      @include red-btn;
    }
  }

  .priority_text {
    font-size: 1.2rem;
    color: rgb(255, 255, 255);
    user-select: none;
    background-color: $bg-top-menu-btn-default;
    padding: 3px;
    border: 1px solid $bg-top-menu-btn-hover;
    border-radius: 5px;
    text-align: center;
  }
