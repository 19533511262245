@import "/src/style/variables.scss";
@import "/src/style/mixins.scss";

.wrapper {

  .header{
    margin-bottom: 10px;

    button{
        @include blue_btn;
        margin-right: 5px;
    }
}
 
  .cols_3 {
    display: grid;

    justify-content: center;
    gap: 10px;

    &.resized{
      grid-template-columns: 12fr;
      max-width: 800px;
      margin-left: auto;
      margin-right: auto;
      gap: 0;
    }

    &.not_resized{
      grid-template-columns: 6fr 6fr;

    }
  }

  padding: 20px;

  .grouped_charts {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
