body {
  margin: 0;
  font-family: "Inter";
}

textarea,
input,
select,
button {
  font-family: "Inter";
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

* {
  user-select: none;
}

.resized_dynamic_menu,
.dynamic_menu {
  position: absolute;
  overflow: hidden;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
}

.dynamic_menu {
  grid-template-columns: 1.4fr 6fr 1.4fr;
}

.resized_dynamic_menu {
  grid-template-columns: 1.7fr 6fr 3fr;
}

.resizable {
  background-color: red;
  z-index: 99999;
}

.react-flow__attribution a {
  display: none;
}

/* Customize the scrollbar track */
::-webkit-scrollbar {
  width: 5px;
  /* Width of the scrollbar */
  height: 5px;
  background-color: rgb(213, 232, 243);
}

/* Customize the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background-color: rgb(74, 148, 190);
  border-radius: 6px;
  /* Rounded corners for the thumb */
}

/* Customize the scrollbar track on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: rgb(31, 108, 153);
  /* Color of the thumb on hover */
}

/* Customize the scrollbar track when pressed */
::-webkit-scrollbar-thumb:active {
  background-color: rgb(24, 82, 116);
  /* Color of the thumb when pressed */
}
