@import "/src/style/variables.scss";
@import "/src/style/mixins.scss";

.wrapper {

    padding: 10px;

    header {
        color: $color-font-main;
    }

    .table_container {
        overflow-y: auto;
        max-height: 500px;
        border-bottom: 1px solid $color-list-border-darker;

        table {

            @include server_table;
            table-layout: fixed;
            font-size: 0.9rem;

            tbody {
                @include user_group_table_body;

                .contacts {
                    font-size: 0.8rem;

                    @media screen and (max-width:$breakpoint-md) {
                        font-size: 0.6rem;
                    }

                    span {
                        font-weight: bold;
                    }

                }

                .table_actions {
                    @include user_group_table_actions_column;
                }
            }


        }
    }

    .add_user_wrapper {
        margin-top: 10px;

        button {
            @include blue_btn;
        }
    }

}