@import "/src/style/mixins.scss";

.section_container {
  @include section_container;

  input[type="color"] {
    background-color: white;
    border: 0;
    border-radius: 50%;

  }


  .properties_list {
    li {
      display: grid;
      grid-template-columns: 3fr 9fr;
      align-items: stretch;
      justify-items: start;
      padding-left: 10px;
      height: 40px;
      border-bottom: 1px solid rgb(238, 238, 238);
    }

  }
}

.section_header {
  @include section_header_container;
}