@import "/src/style/variables.scss";
@import "/src/style/mixins.scss";

.wrapper {
  z-index: 100;
  position: relative;
  display: flex;
  justify-content: right;

  &.opened {
    translate: (0px);
  }

  &.closed {
    translate: (100%);
  }

  transition: translate 0.3s ease-out;

  .resizable_btn {
    position: absolute;
    top: 0;
    right: 20px;
    top: 10px;
    z-index: 9999;
    background-color: $bg-top-menu-btn-default;
    color: white;
    padding: 5px 10px 3px 10px;
    border-radius: 5px;
    cursor: pointer;

    &.resized {
      color: $bg-top-menu-btn-default;
      background-color: white;
    }
  }
}

.right_panel_container {
  width: 100%;
  height: 97vh;
  overflow-y: auto;
  background-color: $bg-node-nav-container;
  box-shadow: $shadow-node-nav-container;

  .section_container {
    @include section_header_container;
  }

  .minimap_wrapper {
    background-color: red;
    position: absolute;
    bottom: 40vh;

    &.opened {
      opacity: 100%;
    }

    &.closed {
      opacity: 0;
    }

    transition: opacity ease-in-out 0.3s;

    .minimap {
      border-radius: 5px;
      background-color: rgb(255, 255, 255);
      box-shadow: $shadow-inset;
      z-index: 9000;
    }
  }
}

.toggle_btn {
  position: absolute;
  top: 0px;

  z-index: 1000;
  left: -30px;

  button {
    height: 50px;
    border: 0;
    width: 30px;
    background-color: $bg-node-nav-container;
    border-left: 1px solid rgb(207, 207, 207);
    border-bottom: 1px solid rgb(207, 207, 207);
    border-bottom-left-radius: 5px;
    font-size: 1.3rem;
    color: $color-node-nav-icon;
    cursor: pointer;
  }
}
