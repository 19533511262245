.wrapper{

    header{
        margin-bottom: 20px;
    }
    textarea{
        width: 100%;
        height: 100px;
        resize: none;
        outline: none;
        border: 1px solid rgb(204, 204, 204);
    }
}