@import "/src/style/mixins.scss";
@import "/src/style/variables.scss";

.wrapper {
  .icon_wrapper {
    @include notification_icon_wrapper;
    .icon {
      @include notification_icon;
    }
    .badge {
      @include red_badge_icon;
    }
  }

  .notifications_list {
    @include notifications_list;

    .left_column {
      .message {
        font-style: italic;
        font-size: 0.8rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* Количество строк, которые вы хотите отобразить */
        -webkit-box-orient: vertical;
      }
    }

    .right_column {
      display: flex;
      justify-content: flex-end;

      .date {
        font-size: 0.7rem;
      }

      .view {
        button {
          @include blue_btn;
        }
      }
    }

    .clear_btn {
      @include blue_btn;
    }
  }
}
