@import "/src/style/mixins.scss";


.wrapper {

  @include section_container;

  .grid_item {
    @include section_grid_item;
  }

}