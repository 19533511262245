@import "/src/style/variables.scss";
@import "/src/style/mixins.scss";

.wrapper {
    h3 {
        color: $color-font-main;
        margin-bottom: 10px;
    }

    .table_wrapper {

        table {
            @include server-table;
            table-layout: fixed;

            td{
                height: 100px;
                text-align: center;
            }


            select,
            input {
                border: none;
                text-overflow: ellipsis;
            }

         
            .textarea_wrapper{
                textarea{
                    border: none;
                    resize: none;
                    padding: 10px;
                    height: 70px;
                    width: 90%;
                }
            }

            select {
                margin-left: 0;
                width: 100%;
            }

            .checkbox_wrapper{
               text-align: center;
            }

            .timestamps{
                font-size: 0.8rem;
            }

       
            .action_btns_wrapper {
                display: flex;
                flex-direction: column;

                button {

                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: 5px;
                }

                .save_btn {
                    @include blue_btn;
                }

                .delete_btn {
                    @include red_btn;
                }
            }

            margin-bottom: 20px;
        }

    }


    .pagination {
        display: flex;
        justify-content: center;

        button {
            @include blue_btn;
            margin-left: 5px;
            margin-right: 5px;
        }
    }

}