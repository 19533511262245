.wrapper {
    position: absolute;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .content {
        display: grid;
        grid-template-columns: 2fr 10fr;
        height: 100%;

        @media screen and (max-width:1800px) {
   
        }
    }
}