@import "/src/style/variables.scss";
@import "/src/style/mixins.scss";
;

.subs_table {
    table {

        width: 100%;
        padding: 0;
        table-layout: fixed;
        border-collapse: collapse;
        text-align: left;
        ;

        th {
            text-align: center;
            color: $color-node-nav-container-header;
        }

        th,
        td {
            border: 1px solid rgb(226, 226, 226);
            background-color: rgb(255, 255, 255);
            padding: 0;
            margin: 0;
            padding-left: 10px;
            overflow-x: auto;


        }

        td {

            height: 25px;

        }

        input {
            width: 95%;
            overflow-x: auto;
            border: none;

        }

        span {

            display: flex;
            justify-content: center;
            align-items: center;

            button {
               @include blue-btn;
               padding: 3px 5px;
            }

        }

    }

}