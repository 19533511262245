@import "/src/style/variables.scss";
@import "/src/style/mixins.scss";

.container {
  background-color: $bg-node-nav-container;
  z-index: 1000;
  border-bottom: 1px solid rgb(226, 226, 226);
  position: fixed;
  box-shadow: $shadow-designer-top-nav;
  top: 0;
  left: 0;
  right: 0;
  display: grid;
  grid-template-columns: 12fr;
  grid-template-rows: 40px 20px;
  height: 50px;
  .grid {
    display: grid;
    grid-template-columns: 4fr 4fr 4fr;
    padding-top: 5px;
    height: 30px;
    button {
      @include blue-btn;
    }

    .left {
      display: flex;
      align-items: center;
      li {
        list-style: none;
      }
      button {
        margin-left: 10px;
      }
    }

    .central {
      display: flex;
      gap: 7px;
      justify-content: center;
      align-items: center;

      .central_nav_btn {
        @include blue-btn;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        font-size: 0.8rem;

        // color: white;
        // background-color: rgb(82, 159, 204);
        // cursor: pointer;
      }
    }

    .right {
      @include nav_right_section;
    }
  }

  @media print {
    display: none;
  }
}
