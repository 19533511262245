@import "/src/style/variables.scss";
@import "/src/style/mixins.scss";

.wrapper{
    position: relative;
    .header_btn{

        @include blue_btn;
        position: absolute;
        right: 22px;
        top: 40px;
    }
}