@import "/src/style/variables.scss";
@import "/src/style/mixins.scss";
.wrapper {
  @include dropdown_menu_wrapper;
  width: 200px;

  z-index: 1000;

  li {
    padding-left: 20px;
  }

  .settings_icon {
    @include dropdown_menu_icon;
  }

  .settings_title {
    @include dropdown_menu_section_title;
  }
}
