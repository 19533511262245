@import "/src/style/variables.scss";
@import "/src/style/mixins.scss";

.wrapper {
  border: 1px solid rgb(231, 231, 231);

  ul {
    margin-top: 20px;
    padding: 0;
  }

  .list_item {
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 11fr;
    align-items: end;
    height: 30px;
    border-bottom: 1px solid rgb(231, 231, 231);
    padding: 0px 10px;

    .list_item_title {
      font-weight: $font-weight-bolder;
    }

    .list_item_value {
      font-size: 0.9rem;
    }

    label {
      margin-right: 5px;
    }

    input[type="text"] {
      margin-right: 10px;
      margin-left: 0;
      border: 0;
      width: 100%;
      height: 20px;
      padding-left: 5px;

      &:focus {
        outline: none;
        background-color: rgb(236, 234, 234);
      }

      transition: background-color 0.2s ease-in-out;
    }
  }

  .message_wrapper {
    padding: 10px;
    border-bottom: 1px solid rgb(231, 231, 231);

    textarea {
      resize: none;
      width: 100% !important;
      border: 0;

      &:focus {
        outline: none;
        background-color: rgb(236, 234, 234);
      }

      transition: background-color 0.2s ease-in-out;
    }

    .message_header {
      font-weight: $font-weight-bolder;
      margin-bottom: 10px;
    }
  }

  .editor_footer {
    display: flex;
    justify-content: start;
    margin-top: 20px;
    margin-left: 5px;

    button {
      @include blue_btn;
      margin-right: 7px;
    }

    .delete_notification_btn {
      @include red_btn;
    }
  }
}
