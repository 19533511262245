@import "/src/style/variables.scss";
@import "/src/style/mixins.scss";
@import "/src/style/variables.scss";
@import "/src/style/mixins.scss";
.wrapper {
  position: relative;
}

.content {
  position: absolute;
  bottom: 50px;
  z-index: 100;

  border: 1px solid rgb(239, 239, 239);
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  margin-bottom: 0;
}

.icon {
  text-align: center;
  color: $color-node-nav-icon;
  margin-bottom: 0;
  padding-bottom: 0;
}

.expandable_content {
  &.expanded {
    height: 200px;
    transition: height 0.3s ease-out;
  }

  &.collapsed {
    height: 0;
    transition: height 0.3s ease-out;
  }
}

.header {
  @include section_header_container;
  padding-bottom: 0;
  margin-bottom: 10px;
  padding-top: 0;
}

.sub_header {
  &.collapsed {
    display: none;
  }

  font-size: 0.9rem;
  margin-bottom: 10px;
  text-align: center;

  ul {
    display: flex;

    padding-left: 10px;
    margin: 0;

    li {
      list-style: none;
      margin-right: 20px;
    }
  }

  input {
    border-radius: 5px;
    height: 18px;
    border: 1px solid rgb(182, 182, 182);
    outline: none;
  }

  button {
    @include blue_btn;
  }

  span {
    position: absolute;
    padding-left: 10px;
    color: red;
    font-style: italic;
  }
}

.body {
  height: 120px;
  overflow-y: auto;
  padding: 10px;
  font-size: 0.8rem;
  background-color: rgb(245, 245, 245);
  border: 1px solid rgb(231, 231, 231);
}
